import { NextPage } from 'next'
import Head from 'next/head'
import { Layout } from '../../components/layout/Layout'
import { ParallaxWrapper } from '../../components/parallax/ParallaxWrapper'
import { RecruiterForm } from '../../components/recruiter-form/RecruiterForm'

const RecruitersPage: NextPage = () => (
  <>
    <Head>
      <title>Information for Recruiters</title>
      <meta property='og:title' content='Informations for Recruiter'/>
      <meta property='og:image' content={`${process.env.NEXT_CDN_URL}/for-recruiters.png`}/>
      <meta property='og:description' content='Important informations for Recruiter'/>
      <meta property='og:url' content='https://violapeter.hu/for-recruiters'/>
    </Head>
    <ParallaxWrapper>
      <Layout>
        <RecruiterForm />
      </Layout>
    </ParallaxWrapper>
  </>
)

export default RecruitersPage
